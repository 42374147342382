import React from 'react';
import { Fragment } from 'react';
import { Alert, Col, Row, Table } from 'reactstrap';
import Logo from '../../assets/img/logo_apjii.png';
// import { useAbsenDewas } from './absenDashboardDewasFunction';
import { useAbsenRegistrasi } from './absenRegistrasiFunction';




const AbsenRegistrasiPage = (props) => {
    const {
        handleCloseAlert, alertValue, listAbsen, sesiAktif, countPeserta, 
        isOpen
        // countPesertaBanned,
        // countPesertaNonBanned
    } = useAbsenRegistrasi();
    return (
        <Fragment>
            <div className="container-absen bg-cover">
                <div className="content-absen">
                    <div className="text-center background-logo">
                        <img src={Logo} alt="APJII" />
                    </div>
                    <div className="alert-absen">
                        <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                            {alertValue.text}
                        </Alert>
                    </div>
                    <div className="text-center absen-title">DAFTAR PESERTA REGISTRASI RAKERNAS & MUNASLUB AD/ART APJII 2023 </div>
                    <div className="text-center absen-title-secondary">{sesiAktif && sesiAktif.nama_sesi ? sesiAktif.nama_sesi : ''} Tanggal {sesiAktif && sesiAktif.tanggal ? sesiAktif.tanggal : ''} </div>
                    {/* <div className="text-center absen-title-secondary blue-text">Total Peserta {countPeserta}, <span className='red-text'>Peserta Hadir {countPesertaBanned}</div> */}
                    <div className="text-center absen-title-secondary">Total Peserta {countPeserta}</div>
                    <Row>
                        <Col lg={12}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} className='jumlah-peserta'>
                                <div
                                    style={{
                                        fontSize: '2.25rem',
                                        fontWeight: 'bold',
                                        color: '#3b82f6',                                        
                                        marginBottom: 20,
                                        textAlign: 'center',
                                    }}
                                    >
                                    Progress Checkin : 
                                    {/* , { countPesertaNonBanned > 0 ? 'Kuorum Minimal : ' + countPesertaNonBanned / 2 + ' + 1' : '' } */}
                                </div>
                                <div
                                    style={{
                                        fontSize: '2.25rem',
                                        fontWeight: 'bold',
                                        // color: listAbsen.length <= ((countPesertaNonBanned / 2) + 1) ? 'rgb(168, 2, 2)' : '#00661f',
                                        color: '#ff7200',
                                        marginBottom: 20,
                                        textAlign: 'center',
                                    }}
                                >
                                    {listAbsen.length}
                                </div>
                                <div
                                    className='blue-text'
                                    style={{
                                        fontSize: '2.25rem',
                                        fontWeight: 'bold',
                                        marginBottom: 20,
                                        textAlign: 'center',
                                    }}
                                >
                                    / {countPeserta}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <Row>
                                <Col lg={6}>
                                <div><i className={`fa fa-circle`} style={{color: isOpen ? 'green' : 'gray'}}/> <span className='font-bold text-[20px]'>{ isOpen ? 'Online' : 'Offline'}</span></div>
                                </Col>
                                <Col lg={6}>
                                    <div style={{ color: 'rgb(59, 130, 246)', fontWeight: 'bold' }} className="float-right">Status Meeting : {sesiAktif && sesiAktif.munas_state}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                            <div className="responsive">
                                <Table responsive className='table-small table-absen'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Perusahaan</th>
                                            <th>Nama</th>
                                            <th>HP</th>
                                            <th>Email</th>
                                            <th>Jam Absen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listAbsen.length <= 0 &&
                                            <tr>
                                                <td colSpan={6} className='text-center italic'>Tidak ada data</td>
                                            </tr>
                                        }
                                        {listAbsen.map((post, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{listAbsen.length - index}</td>
                                                    <td>{post.perusahaan}</td>
                                                    <td>
                                                        <div>{post.nama}</div>
                                                    </td>
                                                    <td>{post.hp_display.substring(0, 3)}********</td>
                                                    <td>{post.email_display}</td>
                                                    <td>{post.jam_absen}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}
export default AbsenRegistrasiPage;