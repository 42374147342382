import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import config from "../../config";
import { useGlobalFunction } from "../../generalFunction/globalFunction";
import api from "../../services/api";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export function useAbsenDewas(props){
    const params = useParams()
    const { id } = params || 0;    
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue
    } = useGlobalFunction();
    const [sesiId, setSesiId] = useState(0);   
    const [countPeserta, setCountPeserta] = useState(0); 
    const [countPesertaBanned, setCountPesertaBanned] = useState(0);
    const [countPesertaNonBanned, setCountPesertaNonBanned] = useState(0);
    const [sesiAktif, setSesiAktif] = useState({
        isActive : false,
        id : 0,
        start_time : null,
        maksimal_time : null,
        maksimal_time_in_time : null,
        maksimal_time_in_minute : null,
        maksimal_time_in_second : null,
    });
    // websocket
    const roomState = {
        filledForm: false,
        messages: [],
        value: '',
        name: '',
        room: 'test',
    }  
    
    const [munasInfo, setMunasInfo] = useState(null);
    const [listAbsen, setListAbsen] = useState([]);
    
    const fetchInitPage = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                sesi_active : 'yes',
                query_type : 'current_date',
                extend_query : 'count_peserta_non_banned'
            }
            // const res = await api.get(`${config.endPoint.munasSesi}?kode=${id}`, {params: {with_sesi : true}}).then(res => res.data)                        
            const res = await api.get(`${config.endPoint.munasSesiDewas}`, {params: params}).then(res => res.data);
            if (res){
                setCountPeserta(res.total_peserta);
                setCountPesertaBanned(res.peserta_banned)
                setCountPesertaNonBanned(res.peserta_non_banned)
                if (res.results.length === 0){
                    setAlertValue(alertValue => ({...alertValue, show: true, text : 'Tidak ada sesi absen untuk hari ini', color: 'danger'}));                
                }else{                
                    setMunasInfo(res.results[0].munas_info);
                    if (res.sesi_aktif && res.sesi_aktif.isActive){
                        setSesiAktif(res.sesi_aktif);
                        setSesiId(res.sesi_aktif.id)
                    }else{                    
                        setSesiAktif(sesiAktif => ({...sesiAktif,
                            isActive: false
                        }))
                    }
                }
            }            
            setIsLoading(false);
            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, 15000)
        }
    },[id, setIsLoading, setAlertValue])

    useEffect(() => {
        fetchInitPage();
    },[fetchInitPage])

    const fetchKehadiran = useCallback(async(tmpSesiId) => {
        setIsLoading(true);
        try{
            let params = {
                sesi_id : tmpSesiId,                
            }
            const res = await api.get(`${config.endPoint.munasAbsenDewas}`, {params: params}).then(res => res.data);            
            if (res){
                setListAbsen(res.results);              
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, 15000)
        }
    }, [setIsLoading, setAlertValue])

    
    useEffect(() => {
        if (sesiId !== 0){
            fetchKehadiran(sesiId);
        }
    },[fetchKehadiran, sesiId])

    // ============= socket io ====================
    const ws = useRef(null);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    // useEffect(() => {
    //     ws.current = new W3CWebSocket(config.wsServer[process.env.NODE_ENV] + roomState.room + '/');
    //     ws.current.onopen = () => console.log("ws opened");
    //     ws.current.onclose = () => console.log("ws closed");

    //     const wsCurrent = ws.current;

    //     return () => {
    //         wsCurrent.close();
    //     };
    //     // eslint-disable-next-line
    // }, []);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {            
            const message = JSON.parse(e.data);            
            if (message && message.text === 'daftar_hadir'){
                console.log('message is >>>', message)
                fetchKehadiran(sesiId)
            }
        };
    }, [fetchKehadiran, sesiId]);


    useEffect(() => {
        console.log('esekusi websocket')
        if (waitingToReconnect) {
            return;
        }

        // Only set up the websocket once
        if (!ws.current) {
            const client = new W3CWebSocket(config.wsServer[process.env.NODE_ENV] + roomState.room + '/');
            new W3CWebSocket(config.wsServer[process.env.NODE_ENV] + roomState.room + '/');
            ws.current = client;

            window.client = client;

            client.onerror = (e) => console.error('errorrrr >>>>>>', e);

            client.onopen = () => {
                setIsOpen(true);
                console.log('ws opened');
                client.send(JSON.stringify({message : 'ping'}));
            };

            client.onclose = () => {

                if (ws.current) {
                    // Connection failed
                    console.log('ws closed by server');
                } else {
                    // Cleanup initiated from app side, can return here, to not attempt a reconnect
                    console.log('ws closed by app component unmount');
                    return;
                }

                if (waitingToReconnect) {
                    return;
                };

                // Parse event code and log
                setIsOpen(false);
                console.log('ws closed');

                // Setting this will trigger a re-run of the effect,
                // cleaning up the current websocket, but not setting
                // up a new one right away
                setWaitingToReconnect(true);

                // This will trigger another re-run, and because it is false,
                // the socket will be set up again
                setTimeout(() => setWaitingToReconnect(null), 5000);
            };

            // client.onmessage = message => {
            //     addMessage(message.data);
            // };


            return () => {

                console.log('Cleanup');
                // Dereference, so it will set up next time
                ws.current = null;

                client.close();
            }
        }
    
}, [waitingToReconnect]);


    

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue, 
        sesiAktif, munasInfo, listAbsen, countPeserta, countPesertaBanned,
        countPesertaNonBanned, isOpen
    }
}