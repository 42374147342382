import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Col, Row, Table } from 'reactstrap';
import config from '../../../../config';
import LoaderComponent from '../../../../containers/Components/LoaderComponent';
import { useGlobalFunction } from '../../../../generalFunction/globalFunction';
import api from '../../../../services/api';


const ResumeKehadiranHarian = (props) => {
    const params = useParams();
    const { id } = params || 0;
    const { isLoading, setIsLoading } = useGlobalFunction();
    const [eventName, setEventName] = useState(null);
    const [listData, setListData] = useState([]);
    const [listTanggal, setListTanggal] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            let param = {
                munas_kode: id
            }
            const res = await api.get(`${config.endPoint.resumeKehadiranHarian}`, { params: param }).then(res => res.data);
            if (res) {
                setListData(res.data);
                setEventName(res.event_info);
                setListTanggal(res.list_tanggal)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
        }
    }, [setIsLoading, id])

    useEffect(() => {
        fetchData();
    }, [fetchData])
    return (
        <Fragment>
            <div style={{ backgroundColor: '#ffffff', padding: 20 }}>
                {isLoading && <LoaderComponent />}
                <Row>
                    <Col lg={12}>
                        <strong>
                            <div className="text-center">RESUME KEHADIRAN HARIAN</div>
                            <div className="text-center">{eventName && eventName.nama}</div>
                        </strong>
                    </Col>
                </Row>
                <div style={{ marginTop: 20 }} />
                <div style={{ paddingTop: 20 }} />
                <Row>
                    <Col lg={12}>
                        <Table className='table-small table-bordered table-center'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Perusahaan</th>
                                    <th>Peserta</th>
                                    {listTanggal.map((tanggal, indexTanggal) => {
                                        return (
                                            <th key={indexTanggal}>{tanggal}</th>
                                        )
                                    })}
                                    <th>Jumlah</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listData.map((peserta, indexPeserta) => {
                                    return (
                                        <tr key={indexPeserta}>
                                            <td className="text-right">{indexPeserta + 1}</td>
                                            <td>{peserta.perusahaan}</td>
                                            <td>{peserta.nama}</td>

                                            { listTanggal.map((tanggal, indexTanggal) => {
                                                return (
                                                    <td key={indexTanggal} className='text-center without-padding'>
                                                        <div
                                                            className='label-td-peserta-banned'
                                                            style={{
                                                                color: peserta.list_tanggal.includes(tanggal) ? '#155724' : '#721c24',
                                                                backgroundColor: peserta.list_tanggal.includes(tanggal) ? '#d4edda' : '#f8d7da',
                                                            }}
                                                        >
                                                            <div style={{}}>{peserta.list_tanggal.includes(tanggal) ? 'V' : 'X'}</div>
                                                        </div>
                                                    </td>
                                                )
                                            })}
                                            <td className='text-center'>{peserta.list_tanggal.length}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default ResumeKehadiranHarian;