import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../config";
import { useGlobalFunction } from "../../generalFunction/globalFunction";
import api from "../../services/api";
import Moment from 'moment';

export function useAbsen(props){
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue,
    } = useGlobalFunction();
    const params = useParams()
    const { id } = params || 0;    
    const [timeLeft, setTimeLeft] = useState(0)
    const [listSesi, setListSesi] = useState([]);
    const [listAbsen, setListAbsen] = useState([]);
    const [isProduction, setIsProduction] = useState(true);
    const [formData, setFormData] = useState({
        kode_absen : null,
        kode_peserta : null,
        munas_id :  null
    })
    const [sesiId, setSesiId] = useState(0);
    const [initSesiId, setInitSesiId] = useState(0);
    const [munasInfo, setMunasInfo] = useState(null);
    const [activeSession, setActiveSession] = useState(false);
    const [eventTime, setEventTime] = useState(null);
    const [sesiAktif, setSesiAktif] = useState({
        isActive : false,
        id : 0,
        start_time : null,
        maksimal_time : null,
        maksimal_time_in_time : null,
        maksimal_time_in_minute : null,
        maksimal_time_in_second : null,
    });

    const fetchInitPage = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.munasSesi}?kode=${id}`, {params: {with_sesi : true}}).then(res => res.data)            
            if (res.results.length === 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Tidak ada sesi absen untuk hari ini', color: 'danger'}));                
            }else{
                setListSesi(res.results);                
                console.log('res >>>', res.sesi_aktif)
                if (res.sesi_aktif){
                    setEventTime(Moment(res.sesi_aktif.maksimal_datetime, "DD-MM-YYYY HH:mm").toDate());                    
                }
                if (res.sesi_aktif && res.sesi_aktif.id){
                    setSesiId(res.sesi_aktif.id);
                    setInitSesiId(res.sesi_aktif.id);
                }
                if (res.sesi_aktif && res.sesi_aktif.isActive){
                    setActiveSession(true);
                }
                if (res.sesi_aktif.maksimal_time_in_seconds > 0){
                    setTimeLeft(res.sesi_aktif.maksimal_time_in_seconds)
                }
                setFormData(formData => ({...formData,
                    kode : id,
                    munas_id : res.results[0].munas
                }));
                setMunasInfo(res.results[0].munas_info);
                if (res.sesi_aktif && res.sesi_aktif.isActive){
                    setSesiAktif(res.sesi_aktif);
                }else{                    
                    setSesiAktif(sesiAktif => ({...sesiAktif,
                        isActive: false
                    }))
                }
            }
            setIsLoading(false);
            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, 15000)
        }
    },[id, setIsLoading, setAlertValue])
    
    useEffect(() => {
        fetchInitPage();
    }, [fetchInitPage])

    const fetchDetail = useCallback(async(sesiId) => {
        setIsLoading(true);
        try{
            setListAbsen([]);
            const res = await api.get(`${config.endPoint.munasAbsen}?kode=${id}`, {params: {sesi_id: sesiId}}).then(res => res.data)            
            let tmp = [];
            if (res.results){                
                res.results.map(post => {
                    tmp.push(post)
                    return true;
                })
            }
            if (res){
                setIsProduction(res.is_production)
            }
            setListAbsen(tmp);
            setIsLoading(false);
            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, 15000)
        }
    },[id, setIsLoading, setAlertValue])

    useEffect(() => {
        if (initSesiId !== 0){
            fetchDetail(initSesiId);
        }
    }, [fetchDetail, initSesiId])


    // useEffect(() => {
    //     if (!timeLeft) {            
    //         return;            
    //     }
            
    //     const intervalId = setInterval(() => {
    //       setTimeLeft(timeLeft - 1);
    //     }, 1000);        
    //     return () => clearInterval(intervalId);        
    // }, [timeLeft]);

    
    const handleChange = (e) => {
        const { value }  = e.target;
        setPageValue(pageValue => ({...pageValue, search: value}))
    }

    const handleSearch = async() => {
        setIsLoading(true);
        try{
            let tmp = {...formData};
            tmp['kode_peserta'] = pageValue.search
            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(tmp))

            let res = await api.post(`${config.endPoint.munasPostAbsen}`, newForm).then(res => res.data);
            // console.log('res data is >>>>', res.data)
            let tmp_list_absen = [...listAbsen]
            if (res){            
                // res.data.map(post => {
                //     let mytrap = tmp_list_absen.findIndex(x => x.id === post.id)
                //     if (mytrap > -1){                    
                //         let array = [...tmp_list_absen];
                //         array[mytrap] = {...array[mytrap], jam_absen : post.jam_absen};
                //         tmp_list_absen = array;                        
                //     }else{            
                //         tmp_list_absen = [post, ...tmp_list_absen];            
                //         // tmp_list_absen.push(post);
                //     }
                //     return true;
                // })  
                let mytrap = tmp_list_absen.findIndex(x => x.id === res.data.id)
                if (mytrap > -1){                    
                    let array = [...tmp_list_absen];
                    array[mytrap] = {...array[mytrap], jam_absen : res.data.jam_absen};
                    tmp_list_absen = array;                        
                }else{            
                    tmp_list_absen = [res.data, ...tmp_list_absen];            
                    // tmp_list_absen.push(post);
                }

                setListAbsen(tmp_list_absen)
                setPageValue(pageValue => ({...pageValue, search: ''}))                
            }
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);
            const { response } = error;            
            let msg = 'Unable to save data';            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color: 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, 15000)
            setPageValue(pageValue => ({...pageValue, search: ''}))       
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handlePrint = () => {
        localStorage.setItem('sesiId', sesiId);
        // window.open(`/#/absen/${post.kode_encode}/`, "_blank")
        window.open(`/#/cetak-absen`, "_blank")        
    }

    const handleSetSesiActive = (post) => {        
        setSesiAktif(sesiAktif => ({...sesiAktif,
            isActive : post.isActive,
            id : post.id,
            start_time : post.start_time_display,
            maksimal_time : post.maksimal_time,
            maksimal_time_in_time : post.maksimal_time_in_time,
            maksimal_time_in_minute : post.maksimal_time_in_minute,
            maksimal_time_in_second : post.maksimal_time_in_seconds,
        }));
        setActiveSession(post.isActive);
        setTimeLeft(post.maksimal_time_in_seconds);    
        setEventTime(Moment(post.maksimal_datetime, "DD-MM-YYYY HH:mm").toDate());
        setSesiId(post.id);
        fetchDetail(post.id);
        fetchTimeLeft(post.id);
        
    }
    

    const fetchTimeLeft = useCallback(async(sesiId) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.munasSesi}${sesiId}/`).then(res => res.data);
            if (res){
                setTimeLeft(res.results.maksimal_time_in_seconds);                
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);

        }
    },[setIsLoading])

    useEffect(() => {
        if (parseFloat(timeLeft) < 1.1 && parseFloat(timeLeft) > -0.1) {
            fetchInitPage();
            return;            
        }
    },[fetchInitPage, timeLeft])
    

    const handleAddMinute = async(params) => {
        setIsLoading(true);
        try{
            let tmpData = {
                'minute' : params,
                'sesi_id' : sesiAktif.id
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpData))
            const res = await api.put(`${config.endPoint.addMinuteSesi}${sesiAktif.id}/`, newForm).then(res => res.data);
            if (res){
                fetchInitPage();
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            const { response } = error;            
            let msg = 'Unable to save data';            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color: 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, 15000)
        }
    }

    
    // ======================== timer ===============================
    const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

    const leading0 = (num) => {
		return num < 10 ? "0" + num : num;
	};

    
    // eslint-disable-next-line
    const getTimeUntil = (deadline) => {
		const time = Date.parse(deadline) - Date.parse(new Date());
		if (time < 0) {
			setDays(0);
			setHours(0);
			setMinutes(0);
			setSeconds(0);
		} else {
			setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
			setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
			setMinutes(Math.floor((time / 1000 / 60) % 60));
			setSeconds(Math.floor((time / 1000) % 60));
		}
	};
    
    useEffect(() => {
		if (eventTime){
			setInterval(() => getTimeUntil(eventTime), 1000);
	
			return () => getTimeUntil(eventTime);
		}
        // eslint-disable-next-line
	}, [eventTime, getTimeUntil]);

    
    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue, timeLeft, listSesi, listAbsen,
        handleChange, handleSearch, handleKeyDown, munasInfo, handlePrint, activeSession,
        handleSetSesiActive, sesiAktif, isProduction, handleAddMinute,
        leading0, days, hours, minutes, seconds, eventTime
    }
}