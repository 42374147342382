import React, { Fragment, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Col, Row, Table } from "reactstrap";
import config from "../../../../config";
import LoaderComponent from "../../../../containers/Components/LoaderComponent";
import { useGlobalFunction } from "../../../../generalFunction/globalFunction";
import api from "../../../../services/api";


const PesertaNonBannedPageTtd = (props) => {
    const params = useParams();
    const {
        isLoading, setIsLoading
    } = useGlobalFunction();
    const { id } = params || 0;
    const [listData, setListData] = useState([]);
    const [listSesi, setListSesi] = useState([]);
    const [eventInfo, setEventInfo] = useState(null);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            setIsLoading(false);
            let params = {
                munas_kode: id
            }
            const res = await api.get(`${config.endPoint.pesertaNonBanned}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(res.results);
                setListSesi(res.list_sesi);
                setEventInfo(res.event_info)
            }
        } catch (error) {
            setIsLoading(false);
        }
    }, [setIsLoading, id])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return (
        <Fragment>
            <div style={{ backgroundColor: '#ffffff', padding: 20 }}>
                {isLoading && <LoaderComponent />}
                <Row>
                    <Col lg={12}>
                        <strong>
                            <div className="text-center">DAFTAR PESERTA TERKONFIRMASI</div>
                            <div className="text-center">{eventInfo && eventInfo.nama}</div>
                        </strong>
                    </Col>
                </Row>
                <div style={{ marginTop: 20 }} />
                <div style={{ paddingTop: 20 }} />
                <Row>
                    <Col lg={12}>
                        <Table className='table-small table-bordered table-center'>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>#</th>
                                    <th rowSpan={2}>Perusahaan</th>
                                    <th rowSpan={2}>Peserta</th>
                                    {listSesi.map((tanggal, indexTanggal) => {
                                        return (
                                            <th colSpan={tanggal.list_sesi.length} key={indexTanggal}>{tanggal.tanggal}</th>
                                        )
                                    })}
                                    <th rowSpan={2}>Tanda Tangan</th>
                                </tr>
                                <tr>
                                    {listSesi.map((tanggal, indexTanggal) => {
                                        return (
                                            <Fragment key={indexTanggal}>
                                                { tanggal.list_sesi.map((sesi, indexSesi) => {
                                                    return (
                                                        <th key={indexSesi + indexTanggal}>{sesi.nama}</th>
                                                    )
                                                })}
                                            </Fragment>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {listData.map((peserta, indexPeserta) => {
                                    return (
                                        <tr key={indexPeserta}>
                                            <td className="text-right">{indexPeserta + 1}</td>
                                            <td>{peserta.perusahaan}</td>
                                            <td>{peserta.nama}</td>

                                            { listSesi.map((tanggal, indexTanggal) => {
                                                return (
                                                    <Fragment key={indexTanggal}>
                                                        { tanggal.list_sesi.map((sesi, indexSesi) => {
                                                            return (
                                                                <td
                                                                    className='text-center without-padding' key={indexSesi + indexTanggal}
                                                                >
                                                                    <div
                                                                        className='label-td-peserta-banned'
                                                                        style={{
                                                                            color: peserta.list_sesi.includes(sesi.id) ? '#155724' : '#721c24',
                                                                            backgroundColor: peserta.list_sesi.includes(sesi.id) ? '#d4edda' : '#f8d7da',
                                                                        }}
                                                                    >
                                                                        <div>{peserta.list_sesi.includes(sesi.id) ? <i className='fa fa-check'></i> : <i className='fa fa-times'></i>}</div>
                                                                    </div>
                                                                </td>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            })}
                                            <td>{indexPeserta + 1}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default PesertaNonBannedPageTtd