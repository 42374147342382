import React, { Fragment } from 'react';
import { Alert, Button, ButtonGroup, Col, Input, InputGroup, InputGroupAddon, Row, Table } from 'reactstrap';
import Logo from '../../assets/img/logo_apjii.png';
import LoaderComponent from '../../containers/Components/LoaderComponent';
import { useAbsen } from './absenFunction';

const AbsenPage = (props) => {
    const { isLoading, alertValue, handleCloseAlert, timeLeft, listSesi,
        pageValue, handleChange, handleSearch, handleKeyDown, listAbsen, munasInfo,
        handlePrint, activeSession, handleSetSesiActive, sesiAktif, isProduction,
        handleAddMinute,
        leading0, 
        // days, 
        hours, minutes, seconds
    } = useAbsen();

    
    
    // console.log('timeLeft >>>>>', timeLeft)
    // // console.log('res >>>', Math.floor(timeLeft / 60) > 60 ? Math.floor(timeLeft / 60) - 60 : Math.floor(timeLeft / 60))
    // console.log('res >>>', Math.floor(timeLeft / 60) > 60 ? 'lebih 60' : 'dibawah 60')

    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}    
            <div className="container-absen bg-cover">                
                <div className="text-center background-logo">
                    <img src={Logo} alt="APJII" />
                </div>
                <div className="content-absen">
                    <div className="text-center absen-title">PORTAL ABSENSI {munasInfo && munasInfo.nama_munas}</div>
                    <div className="alert-absen">
                        <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                            {alertValue.text}
                        </Alert>
                    </div>
                    <div className="search-absen">
                        <Row>
                            <Col lg={4}>
                                <InputGroup>
                                    <Input type="text" id="input1-group2" name="search" placeholder="scan barcode disini" value={pageValue.search} onChange={handleChange} onKeyDown={handleKeyDown} disabled={!sesiAktif.isActive}/>
                                    <InputGroupAddon addonType="append">
                                        <Button type="button" size="sm" color="primary" onClick={handleSearch} disabled={!sesiAktif.isActive}>{props.btnTitle ? props.btnTitle : 'Input Absen'}</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col lg={8}>
                                <div className="float-right">
                                    <ButtonGroup>
                                        <Button color='success btn-cetak-absen' onClick={handlePrint}><i className='fa fa-print'></i> Cetak Absen</Button>
                                        <Button color='info btn-cetak-absen' onClick={() => handleAddMinute(5)}><i className='fa fa-history'></i> + 5 Menit</Button>
                                        <Button color='warning btn-cetak-absen' onClick={() => handleAddMinute(10)}><i className='fa fa-history'></i> + 10 Menit</Button>
                                        <Button color='danger btn-cetak-absen' onClick={() => handleAddMinute(30)}><i className='fa fa-history'></i> + 30 Menit</Button>
                                    </ButtonGroup>
                                </div>
                            </Col>
                        </Row>                    
                    </div>
                    <div style={{marginBottom: 20}}/>                    
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <div className="responsive">
                                <Table responsive className='table-small table-absen'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Perusahaan</th>
                                            <th>Nama</th>
                                            <th>HP</th>
                                            <th>Email</th>                                            
                                            <th>Jam Absen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { listAbsen.length <= 0 &&
                                            <tr>
                                                <td colSpan={6} className='text-center italic'>Tidak ada data</td>
                                            </tr>
                                        }                              
                                        { listAbsen.map((post, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{listAbsen.length - index}</td>
                                                    <td>{post.perusahaan}</td>
                                                    <td>
                                                        <div>{post.nama}</div>
                                                        { !isProduction &&
                                                            <div style={{backgroundColor: '#e1e9f2', padding: 10}}>Login Kode : {post.auth_kode}</div>
                                                        }
                                                    </td>
                                                    <td>{post.hp_display}</td>
                                                    <td>{post.email_display}</td>                                                    
                                                    <td>{post.jam_absen}</td>                                                    
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                            { activeSession && timeLeft && timeLeft > 0 ?
                                <Fragment>
                                    <div className="absen-timer-title">BATAS ABSEN</div>
                                    <div className="d-flex absen-timer">
                                        <div>{leading0(hours)}</div>
                                        <div>:</div>
                                        <div>{leading0(minutes)}</div>
                                        <div>:</div>
                                        <div>{leading0(seconds)}</div>
                                    </div>                            
                                </Fragment>
                                :
                                <Fragment>
                                    <div className="absen-timer-title">BATAS ABSEN</div>
                                    <div className="absen-timer" style={{fontSize: 16, marginBottom: 20}}>Waktu Absen sudah habis</div>
                                </Fragment>
                            }
                            <Table responsive className='table-small table-absen'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Sesi</th>
                                        <th>Waktu</th>
                                        <th>Maksimal Absen</th>
                                        <th>Aktif</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { listSesi.map((post, index) => {
                                        return(
                                            <tr key={index} className={post.id ===  sesiAktif.id ? 'active' : ''}>
                                                <td style={{cursor: 'pointer'}} onClick={() => handleSetSesiActive(post)}>{index+1}</td>
                                                <td style={{cursor: 'pointer'}} onClick={() => handleSetSesiActive(post)}>{post.tanggal_display} - {post.nama_sesi}</td>
                                                <td style={{cursor: 'pointer'}} onClick={() => handleSetSesiActive(post)}>{post.start_time_display} s.d {post.end_time_display}</td>                                                
                                                <td style={{cursor: 'pointer'}} onClick={() => handleSetSesiActive(post)}>{post.maksimal_time}</td>
                                                <td style={{cursor: 'pointer'}} onClick={() => handleSetSesiActive(post)}>{post.isActive ? "Aktif" : "Tidak Aktif"}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>

        </Fragment>
    )
}
export default AbsenPage;