import axios from 'axios';
import config from '../config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const headers = { 'Accept' : 'application/json', 'Content-Type' : 'application/json' }



const api = axios.create({
    baseURL : config.apiBaseUrl[process.env.NODE_ENV],
    headers: headers,    
    timeout : 50000
});


api.interceptors.request.use(
    function(configure) {        
        const myCookies = cookies.get(config.cookies.TOKEN_COOKIES);             
        // configure.headers["Access-Control-Allow-Credentials"] = true;
        if (myCookies) {                    
            configure.headers["Authorization"] = 'Token ' + myCookies.token;
        }
        return configure;
    },
    function(error) {
        return Promise.reject(error);             
    }
);



export default api;