export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
      // production: "http://127.0.0.1:8001", 
      // wsServer : "ws://127.0.0.1:8001/ws/",
        // development: "https://belakangsolo.apjii.or.id",
        development: "http://127.0.0.1:8001",
        // production: "http://127.0.0.1:8001",
        // production: "https://belakangsolo.apjii.or.id", 
        // wsServer : "wss://belakangsolo.apjii.or.id/ws/",
        // development: "https://belakangopm2023.apjii.or.id",
        // production: "https://belakangopm2023.apjii.or.id", 
        // wsServer : "wss://belakangopm2023.apjii.or.id/ws/",
        // development: "https://backendsite.apjii.or.id",
        production: "https://backendsite.apjii.or.id", 
        // wsServer : "wss://backendsite.apjii.or.id/ws/",
        // development: "https://apjiiabsen.myappscenter.com",
        // production: "https://apjiiabsen.myappscenter.com",         
        // wsServer : "wss://apjiiabsen.myappscenter.com/ws/",
    },
    wsServer : {
      development : "ws://127.0.0.1:8000/ws/",
      // production : "ws://127.0.0.1:8001/ws/",
      production : "wss://backendsite.apjii.or.id/ws/",
    },
    // targetReceiveTokenMsg : "https://evoteopm.apjii.or.id",
    targetReceiveTokenMsg : {
      development : "http:///localhost:3001",
      // production :  "http:///localhost:3000",
      production :  "https:///evote.apjii.or.id",
    },
    // targetReceiveTokenMsg : "https://evote.myappscenter.com",
    // targetReceiveTokenMsg : "https://e-vote.apjii.or.id",
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__renataKidsUser",
      ACCESS_TOKEN: "__renataKidsValue",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm",
      DEF_FB : "__defFb", 
    },
    cookies :{
      TOKEN_COOKIES : '__eventAPJII__'
    },
    expiresSession : 86400,
    itemPerPage : 10,
    timeOutValue : 5000,
    endPoint : {
      settingObject : `/master/api/v1/setting-object/`,
      perusahaan : `/master/api/v1/perusahaan/`,
      provinsi : `/master/api/v1/provinsi/`,
      kabupaten : `/master/api/v1/kabupaten/`,
      kontak : `/kontak/api/v1/kontak/`,
      importKontak : `/kontak/api/v1/import-kontak/`,
      importKontakWithoutZip : `/kontak/api/v1/try-import-kontak-withoutzip/`,
      saveImportKontakWithoutZip : `/kontak/api/v1/save-import-kontak-withoutzip/`,
      saveImportKontak : `/kontak/api/v1/save-import-kontak/`,
      login : `/auth/api/newlogin`,
      munas : `/munas/api/v1/munas/`,
      munasNonPeserta : `/munas/api/v1/munas-non-peserta/`,
      regenerateIDCard : `/munas/api/v1/regenerate-idcard/`,
      // munasPeserta : `/munas/api/v1/munas/`,
      munasChangeState : `/munas/api/v1/munas-change-state/`,
      resendEmailLoginVote : `/munas/api/v1/resend-email-loginvote/`,
      downloadZipIDCard : `/munas/api/v1/download-zip-idcard`,
      createIdCard : '/munas/api/v1/generate-idcard-all/',

      kategoriPeserta : `/master/api/v1/kategoripeserta/`,
      munasPerusahaan : `/munas/api/v1/munaspeserta-perusahaan/`,
      munasPeserta : `/munas/api/v1/munaspeserta/`,
      munasSesi : `/munas/api/v1/munas-sesi/`,
      munasSesiDewas : `/munas/api/v1/munas-sesi-guest/`,
      munasSesiRegistrasi : `/munas/api/v1/munas-sesi-registrasi/`,
      munasPostAbsen : `/munas/api/v1/munas-post-absen/`,
      munasAbsen : `/munas/api/v1/munas-absen/`,
      munasAbsenDewas : `/munas/api/v1/munas-absen-guest/`,
      pesertaMunas : `/munas/api/v1/peserta/`,
      isProduction : `/master/api/v1/isproduction/`,
      addMinuteSesi : `/munas/api/v1/add-minute-sesi/`,
      pesertaBanned : `/munas/api/v1/peserta-banned/`,
      pesertaNonBanned : `/munas/api/v1/peserta-non-banned/`,
      resumeKehadiranHarian : `/munas/api/v1/resume-kehadiran-harian/`,
      votePauseStart : `/vote/api/v1/pause-start-vote/`,
      voteAddWaktu : `/vote/api/v1/add-waktu/`,
      voteChangeView : `/vote/api/v1/change-view/`,
      absenTidakHadir : `/munas/api/v1/absen-tidak-hadir/`,

      // vote
      vote : `/vote/api/v1/vote/`,
    }
};
  