import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Alert, Col, Row, Table } from 'reactstrap';
import config from '../../config';
import LoaderComponent from '../../containers/Components/LoaderComponent';
import { useGlobalFunction } from '../../generalFunction/globalFunction';
import api from '../../services/api';

const AbsenPrint = (props) => {
    const {
        alertValue, setAlertValue, handleCloseAlert, isLoading, setIsLoading
    } = useGlobalFunction()
    const [listData, setListData] = useState([]);
    const [info, setInfo] = useState(null);
    const sesiId = localStorage.getItem('sesiId') || 0;

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        console.log('sesi Id is >>>>', sesiId)
        try{
            const res = await api.get(`${config.endPoint.munasAbsen}`, {params: {sesi_id : sesiId, orderType: 'asc'}}).then(res => res.data)
            if (res){
                setListData(res.results);
            }
            if (res && res.results.length > 0){
                setInfo(res.results[0].munas_info);
            }  
            setIsLoading(false);
            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, 15000)
        }
    },[setIsLoading, setAlertValue, sesiId])

    useEffect(() => {
        fetchData();
    },[fetchData])

    

    return(
        <Fragment>
            <div style={{backgroundColor: '#ffffff', padding: 20}}>
                { isLoading && <LoaderComponent/>}
                <Alert isOpen={alertValue.show} toggle={handleCloseAlert} color={alertValue.color}>
                    {alertValue.text}
                </Alert>
                <div style={{paddingTop: 20}}/>
                <Row>
                    <Col lg={12}>
                        <strong>
                            <div className="text-center">DAFTAR KEHADIRAN {info && info.nama_munas}</div>
                            <div className="text-center">{info && info.tempat_munas} {info && info.tanggal_display}</div>
                            <div className="text-center">{info && info.nama_sesi} {info && info.start_time_display} s.d {info && info.end_time_display}</div>
                        </strong>
                    </Col>
                </Row>
                <div style={{marginTop: 20}}/>
                <Table className='table-small table-bordered table-center'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Perusahaan</th>
                            <th>Nama</th>
                            <th>HP</th>
                            <th>Email</th>
                            <th>Jam Absen</th>
                        </tr>
                    </thead>
                    <tbody>                                        
                        { listData.map((post, index) => {
                            return(
                                <tr key={index}>
                                    <td className="text-right">{index+1}</td>
                                    <td>{post.perusahaan}</td>
                                    <td>{post.nama}</td>
                                    <td>{post.hp_display}</td>
                                    <td>{post.email_display}</td>
                                    <td>{post.jam_absen}</td>   
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    )
}
export default AbsenPrint;