import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
// import './style/customStyle.css';

import { unregister } from './serviceWorker';
import AbsenPage from './views/Absen/AbsenPage';
import AbsenPrint from './views/Absen/AbsenPrint';
import AbsenDashboardPage from './views/AbsenDashboard/AbsenDashboardPage';
import PesertaBannedPage from './views/Munas/Laporan/PesertaBanned/PesertaBannedPage';
import PesertaNonBannedPage from './views/Munas/Laporan/PesertaNonBanned/PesertaNonBannedPage';
import PesertaNonBannedPageTtd from './views/Munas/Laporan/PesertaNonBanned/PesertaNonBannedPageTtd';
import ResumeKehadiranHarian from './views/Munas/Laporan/ResumeKehadiranHarian/ResumeKehadiranHarian';
import AbsenMunasDash from './views/AbsenDashboard/AbsenMunasDash';
import AbsenDashboardHomepage from './views/AbsenDashboard/AbsenDashboardHomepage';
import AbsenRegistrasiPage from './views/AbsenDashboard/AbsenRegistrasiPage';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const LoginPage = React.lazy(() => import('./views/Authentication/LoginPage'));

// const CetakMasterBill = React.lazy(() => import('./views/Room/Room/CetakMasterBill'));
unregister();

const App = () => {
	return (
		<HashRouter>
			<React.Suspense fallback={loading()}>
				<Switch>
					<Route exact path="/login" name="Login Page" render={props => <LoginPage {...props} />} />
					{/* <Route exact path="/laporan/cetak-penjualan" name="Laporan Penjualan" render={props => <PenjualanPrint {...props} />} />
					<Route exact path="/laporan/struk-penjualan" name="Struk Penjualan" render={props => <StrukPrint {...props} />} />
					<Route exact path="/laporan/cetak-pembelian" name="Laporan Pembelian" render={props => <PembelianPrint {...props} />} />
					<Route exact path="/laporan/cetak-pembelian-detail" name="Laporan Pembelian Detail" render={props => <PembelianDetailPrint {...props} />} />

					<Route exact path="/laporan/cetak-hutang" name="Laporan Hutang" render={props => <HutangPrint {...props} />} />
					<Route exact path="/laporan/cetak-hutang-detail" name="Laporan Hutang Detail" render={props => <HutangDetailPrint {...props} />} />

					<Route exact path="/laporan/cetak-piutang-penjualan" name="Laporan Piutang" render={props => <PiutangPrint {...props} />} />
					<Route exact path="/laporan/cetak-piutang-detail" name="Laporan Piutang Detail" render={props => <PiutangDetailPrint {...props} />} />
					<Route exact path="/laporan/cetak-piutang-pegawai-detail" name="Laporan Piutang Pegawai Detail" render={props => <PiutangPegawaiDetailPrint {...props} />} />
					
					<Route exact path="/laporan/cetak-pengeluaran" name="Laporan Pengeluaran" render={props => <PengeluaranPrint {...props} />} />
					
					<Route exact path="/laporan/cetak-profit-penjualan" name="Laporan Profit Penjualan" render={props => <PenjualanProfitPrint {...props} />} />
					
					<Route exact path="/laporan/cetak-produksi-detail" name="Laporan Produksi Detail" render={props => <ProduksiDetailPrint {...props} />} />

					<Route exact path="/laporan/cetak-profit-and-loss" name="Laporan Produksi Detail" render={props => <ProfitAndLossPrint {...props} />} />
					
					<Route exact path="/laporan/cetak-deposite-detail" name="Laporan Deposite Detail" render={props => <DepositeDetailPrint {...props} />} />
					<Route exact path="/laporan/cetak-produk" name="Daftar Produk" render={props => <ProdukPrint {...props} />} /> */}
					
					<Route exact path="/absen" name="Absen" render={props => <AbsenPage {...props} />} />					
					<Route exact path="/cetak-absen" name="Cetak Absen" render={props => <AbsenPrint {...props} />} />					
					<Route exact path="/absen/:id" name="Absen" render={props => <AbsenPage {...props} />} />
					<Route exact path="/absen-dashboard" name="Absen" render={props => <AbsenDashboardPage {...props} />} />
					<Route exact path="/absen-dashboardmunas" name="Absen" render={props => <AbsenMunasDash {...props} />} />
					<Route exact path="/absen-registrasi" name="Absen" render={props => <AbsenRegistrasiPage {...props} />} />
					<Route exact path="/absen-homepage" name="Absen" render={props => <AbsenDashboardHomepage {...props} />} />
					<Route exact path="/event/laporan/peserta-banned/:id" name="Laporan Peserta Tidak Hadir lebih dari 4x" render={props => <PesertaBannedPage {...props} />} />
					<Route exact path="/event/laporan/peserta-non-banned/:id" name="Laporan Peserta Terkonfirmasi" render={props => <PesertaNonBannedPage {...props} />} />
					<Route exact path="/event/laporan/peserta-non-banned-ttd/:id" name="Laporan Peserta Terkonfirmasi" render={props => <PesertaNonBannedPageTtd {...props} />} />
					<Route exact path="/event/laporan/resume-kehadiran-harian/:id" name="Resume Kehadiran Harian" render={props => <ResumeKehadiranHarian {...props} />} />
					<Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
				</Switch>
			</React.Suspense>
		</HashRouter>
	);
}

export default App;
